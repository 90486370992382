.App {
  text-align: center;
}
label {
  padding: 0.5rem;
  font-weight: bold;
  font-size: medium;
}

.App-header {
  background-color: #282c34;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

UpdateDeviceForm {
  padding: 10px;
}
SyncForm {
  padding: 10px;
}
FileUpload {
  padding: 10px;
}

.toolheader {
  padding-bottom: 0.5em;
  font-weight: bold;
  font-size: larger;
}

.tool {
  padding-bottom: 5em;
}